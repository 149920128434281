// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#application-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

#application-wrapper > nav {
  display: flex;
}

#application-wrapper > nav > div,
#content > div {
  flex: 1;
}

#sidebar {
  display: flex;
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 8px 8px -8px #07070729;
  box-shadow: 0 0 4px 0 #07070729;
}

#content {
  display: flex;
  flex: 1;
  transition: all 0.5s ease;
  overflow-x: hidden;
}

#application-wrapper > main {
  display: flex;
  flex: 1;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,OAAO;AACT;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,oCAAoC;EACpC,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,OAAO;EACP,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,OAAO;EACP,cAAc;AAChB","sourcesContent":["#application-wrapper {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-items: stretch;\n}\n\n#application-wrapper > nav {\n  display: flex;\n}\n\n#application-wrapper > nav > div,\n#content > div {\n  flex: 1;\n}\n\n#sidebar {\n  display: flex;\n  padding: 12px;\n  border-radius: 16px;\n  box-shadow: 0 8px 8px -8px #07070729;\n  box-shadow: 0 0 4px 0 #07070729;\n}\n\n#content {\n  display: flex;\n  flex: 1;\n  transition: all 0.5s ease;\n  overflow-x: hidden;\n}\n\n#application-wrapper > main {\n  display: flex;\n  flex: 1;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
